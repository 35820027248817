<template>
    <a-modal v-model="VisiblePopProgress" :footer="null" title="历史进度" centered>
        <vue-scroll :ops="ops" style="height:450px">
        <!-- <a-steps progress-dot :current="progressList.length-1" direction="vertical" :status="status?'process':'wait'"> -->
        <a-steps progress-dot :current="progressList.length-1" direction="vertical" status="finish">
            <a-step v-for="(item,index) in progressList" :key="index">
            <template slot="subTitle">
                {{item.start_time}}
                <span v-show="item.end_time">——{{item.end_time}}</span>
            </template>
            <template slot="title">
                {{item.userName}}&ensp;{{item.typeName}}
            </template>
            <template slot="description">
                <span v-show="item.memo">备注：{{item.memo}}</span>
            </template>
            </a-step>
        </a-steps>
        </vue-scroll>
        <div class="btns">
            <a-button type="button" @click="VisiblePopProgress=!VisiblePopProgress" size="large">确定</a-button>
        </div>
    </a-modal>
</template>

<script>
import config from '@/until/configs';
export default {
    data(){
        return{
            progressList:[],
            ops: config.vueScrollSetting,
            VisiblePopProgress:false,//进度弹框
            // status:''
        }
    },
    methods:{
        popProgress () { 
            this.VisiblePopProgress = true;
        },
    },
    // updated(){
    //     this.status=this.progressList[this.progressList.length-1].end_time
    // }
}
</script>

<style>

</style>