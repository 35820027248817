<template>
    <div class="allocation-page">
        <!-- <div class="page-title">
            <span class="name">任务分配</span>
        </div> -->
        <div class="filtrate">
            <a-button type="primary" @click="addTask">新增任务</a-button>
            <a-input-search 
                placeholder="请输入内容"
                @search="onSearch"
                class="fr" 
             />
        </div>
        <a-table :columns="columns" bordered :data-source="allocationList" v-model="keyword" @change="PageChange" :pagination="pagination"  style="padding:10px 20px 0">
            <template slot="jobName" slot-scope="text,record">
                  <span @click="popProgress(record)" class="hoverColor">{{text}}</span>
            </template>
            <template slot="iconInfo" slot-scope="text,record">
                <div  style="display: flex;justify-content: space-between;align-items: baseline;">
                    <span >{{text}}</span>
                    <a-button type="link" class="fr" @click="popup.stop(record)"><a-icon style="fontSize:18px" type="info-circle" /></a-button>
                </div>
            </template>
            <template slot='action' slot-scope="text,record">
                <a @click="toInputs_data(record,0)">查看</a>
                <a-divider type="vertical" />
                <a @click="editTask(record)">编辑</a>
                <a-divider type="vertical" />
                <a @click="inputs_get_deleteLeaderListItem(record.jobId)">删除</a>
                <a-divider type="vertical" />
                <a  @click="inputs_get_onlineJob(record.jobId)">上线</a>
            </template>
        </a-table>
        <a-modal v-model="Visible" :footer="null" title="分配详情" centered>
          <div class="select">
            <!-- 选择小组 -->
            <div class="select-item">
                <a-row type="flex" :gutter="[16,32]">
                <a-col flex="120px">选择小组</a-col>
                <a-col flex="300px">
                    <a-select
                      style="width: 100%"
                      placeholder="选择小组"
                      v-model="VisibleList.groupName"
                      disabled
                    >
                    </a-select>
                </a-col>
            </a-row>
            </div>
            <!-- 任务名称 -->
            <div class="select-item">
                <a-row type="flex" :gutter="[16,32]">
                <a-col flex="120px">任务名称</a-col>
                <a-col flex="300px">
                        <a-select
                        style="width: 100%"
                        placeholder="选择数据库"
                          v-model="VisibleList.jobName"
                          disabled
                        >
                        </a-select>
                </a-col>
            </a-row>
            </div>
            <!-- 分配工作 -->
            <div class="select-item">
                <a-row type="flex" :gutter="[16,32]">
                <a-col flex="120px">分配工作</a-col>
                <a-col flex="200px">
                    <a-select
                    style="width: 100%"
                      v-model="VisibleList.cube"
                      disabled
                    >
                    </a-select>
                </a-col>
                <a-col flex="220px">
                    <div style="width: 220px">
                        <a-select
                        style="width: 100%"
                          :value="'已选地区：'+VisibleList.regionNames"
                          disabled
                        >
                        </a-select>
                    </div>
                </a-col>
                <a-col flex="220px">
                    <div style="width: 220px">
                      <a-select
                        style="width: 100%"
                          :value="'已选时间：'+VisibleList.timeNames"
                          disabled
                        >
                        </a-select>
                    </div>
                </a-col>
            </a-row>
            </div>
            <!-- 时间区间 -->
            <div class="select-item">
                <a-row type="flex" :gutter="[16,32]">
                <a-col flex="120px">时间区间</a-col>
                <a-col flex="200px">
                    <a-date-picker disabled v-model="VisibleList.startDate" valueFormat="YYYY-MM-DD"/>
                </a-col>
                <a-col flex="200px">
                    <a-date-picker disabled v-model="VisibleList.endDate" valueFormat="YYYY-MM-DD"/>
                </a-col>
            </a-row>
            </div>
            <div class="btns">
              <a-button type="button" @click="Visible=!Visible" size="large">确定</a-button>
            </div>
        </div>
        </a-modal>
        <Progress ref="proChild"></Progress>
    </div>
</template>

<script>
import { messageTips } from '@/until/tools';
import Progress from './components/modalProgress.vue'
const columns = [
{
    title: '序号',
    dataIndex: 'sort',
    key: '1',
    align:'center',
  },
  {
    title: '组名',
    dataIndex: 'groupName',
    key: '2',
  },
  {
    title: '任务名',
    dataIndex: 'jobName',
    scopedSlots: { customRender: 'jobName'},
    key: '3',
  },
  {
    title: '数据库',
    dataIndex: 'cube',
    key: '4',
    scopedSlots: { customRender: 'iconInfo'}
  },
  {
    title: '开始时间',
    dataIndex: 'startDate',
    key: '5',
  },
  {
    title: '结束时间',
    dataIndex: 'endDate',
    key: '6',
  },
  {
    title: '当前状态',
    dataIndex: 'status',
    key: '7',
  },
  {
    title: '完成度',
    dataIndex: 'progress',
    key: '8',
  },
    {
    title: '操作',
    key: 'operation',
    align:'center',
    scopedSlots: {
      customRender: 'action',
    },
  },
];
export default {
    components:{
      Progress
    },
    data() {
        return {
            Visible:false,
            VisibleList:[],
            allocationList:[],
            columns,
            keyword:'',
            pagination: {
              total: 0,
              current:1,
              pageSize: 10,//每页中显示10条数据
              showSizeChanger: true,
              showQuickJumper:true,
              pageSizeOptions: ["10", "20", "50", "100"],//每页中显示的数据
              showTotal: total => `共有 ${total} 条数据`,  //分页中显示总的数据
            },
        }
    },
    methods:{
      toInputs_data(record,val){
          let {href} = this.$router.resolve({path: '/inputs_data',
              query:{cubeId:record.cubeId,
                cubeName:encodeURI(record.cube),
                jobId:record.jobId,
                editable:val
              }
            });
          window.open(href, '_blank');
      },
        popup(record){
          this.Visible = true;
          this.VisibleList=record
        },
        popProgress(record){
          this.$refs.proChild.popProgress()
          this.$refs.proChild.progressList=record.logList
        },
        onSearch(value) {
          this.keyword = value
          this.inputs_get_allTaskList(1)
        },
        editTask(record){
            let params ={
              jobId: record.jobId
            }
            this.$http.inputs_get_detail(params).then(res=>{
              if(res.job){
                let obj = JSON.stringify(res.job);
                 this.$router.push({
                  path: '/inputs_addTask',
                  query:{
                    detailList:obj
                  }
                 });
              }
            })
        },
        addTask(){
            this.$router.push('/inputs_addTask');
        },
        //获取列表
        inputs_get_allTaskList(current,size=10){
          let params ={
            currentPage:current??1,
            jobName:this.keyword,
            pageSize:size
          }
          this.$http.inputs_get_allTaskList(params).then(res=>{
            if(res.success){
              this.pagination.total=res.page.allSize;
              this.pagination.pageSize = res.page.pageSize;
              const data =res.list
              if(data!==undefined){
                const temp = data.map((item,index)=>{
                  return{
                    key:index+1,
                    sort:++res.page.indexNum,//排序
                    groupName:item.teamName,//组名
                    cube:item.cubeName,//库名
                    jobName:item.jobName,//任务名
                    status:item.jobStatusName,//状态
                    progress:item.jobProgress+'%',//进度
                    jobId:item.jobId,//任务id
                    cubeId:item.cubeId,//库id
                    teamIds:item.teamId,//组id
                    startDate:item.startDate,
                    endDate:item.endDate,
                    regionNames:item.regionNames,
                    timeNames:item.timeNames,
                    logList:item.logList,
                  }
                })
                this.allocationList=temp
              }else{
                this.allocationList=[]
              }
            }
            if(this.allocationList.length==0){
              this.inputs_get_allTaskList(1)
            }
          console.log( this.allocationList,' this.allocationList this.allocationList');
          })
        },
        //删除任务
        inputs_get_deleteLeaderListItem(e) {
            let params ={
              jobId:e
            }
            var that = this;
            this.$confirm({
                title: '提示',
                content: '您确定要删除吗？',
                okText: '确定',
                cancelText: '取消',
                centered:true,
                onOk() {
                    that.$http.inputs_get_deleteLeaderListItem(params).then(res=>{
                            if(res.success) {
                                messageTips(res.message,3);
                                that.inputs_get_allTaskList(that.pagination.current)
                            } else {
                                messageTips(res.message,1);
                            }
                    })
                }
            });
        },
        //上线任务
        inputs_get_onlineJob(e){
          let params ={
            jobId:e
          }
          this.$http.inputs_get_onlineJob(params).then(res=>{
            if(res.success){
              messageTips(res.message,3)
              setTimeout(() => {
                this.inputs_get_allTaskList()
              }, 500);
            }
          })
        },
        //翻页
        PageChange(pageCurrent){
          this.pagination.current=pageCurrent.current
          let size = pageCurrent.pageSize;
          this.inputs_get_allTaskList(pageCurrent.current,size)
        }
    },
    mounted(){
      this.inputs_get_allTaskList()
    }
}
</script>

<style lang="scss" scoped>
@import '../../scss/allocation.scss';
</style>
