<template>
    <div class="allocation-page">
        <a-tabs v-model="activeKey">
            <template slot="tabBarExtraContent">
              <div class="filtrate" v-show="activeKey==1">
                  <a-input-search 
                      placeholder="请输入查询内容" 
                      @search="onSearch"
                      class="fr"
                  />
              </div>
            </template>
          <a-tab-pane key="1" tab="任务分配"></a-tab-pane>
          <a-tab-pane key="2" tab="所有任务"></a-tab-pane>
          <a-tab-pane key="3" tab="任务管理" v-if="roleName.indexOf('领导')>-1">
            <allocation />
          </a-tab-pane>
        </a-tabs>
        <div v-show="activeKey==1" style="padding:10px 20px;">
          <a-switch checked-children="展开" un-checked-children="收起" v-model="switchChecked" @change="changeSwitch" />
          <a-radio-group v-model="jobStatus" button-style="solid" style="margin-left:20px;" @change="changeStatus">
            <a-radio-button :value="undefined">全部</a-radio-button>
            <a-radio-button :value="0">新分配</a-radio-button>
            <a-radio-button :value="1">录入中</a-radio-button>
            <a-radio-button :value="2">待审核</a-radio-button>
            <a-radio-button :value="3">已审核</a-radio-button>
            <a-radio-button :value="4">已提交</a-radio-button>
            <a-radio-button :value="5">上线中</a-radio-button>
            <a-radio-button :value="6">已上线</a-radio-button>
            <a-radio-button :value="10">上线失败</a-radio-button>
        </a-radio-group>
        </div>
        <a-table :rowKey="(r) => r.jobId" :rowClassName="(record, index) => record.childrenList.length==0 && 'noExpand'" :expandedRowKeys="expandedRowKeys" @expand="expandFP" :pagination="pagination" :columns="columns" bordered @change="PageChange" :data-source="allocationList" v-show="activeKey==1"  style="padding:10px 20px 0">
            <template slot="jobName" slot-scope="text,record">
                  <span @click="popProgress(record)" class="hoverColor" :title="text">{{text}}</span>
            </template>
            <template slot="iconInfo" slot-scope="text,record">
                <div style="display: flex;justify-content: space-between;align-items: baseline;">
                    <span :title="text">{{text|getTxtShow(10)}}</span>
                    <a-button type="link" class="fr" @click.stop="popup(record)"><a-icon style="fontSize:18px" type="info-circle" /></a-button>
                </div>
            </template>
            <template slot="jobProgress" slot-scope="text">
              <div style="position:relative;">
                    <a-progress
                      :percent="Number(text)"
                      :strokeWidth="20"
                      :showInfo="false"
                      strokeColor="rgb(70,217,147)"
                    >
                    </a-progress>
                    <span style="color: #222;position:absolute;line-height:23px;left: 50%;transform: translateX(-50%);">{{text}}%</span>
              </div>
            </template>
            <template slot='action' slot-scope="text,record">
                <a @click="toInputs_data(record,0)">查看</a>
                <a-divider type="vertical" />
                <a @click="toTeamAddTask(record,1)">分配</a>
                <a-divider type="vertical" />
                <a @click="inputs_get_submitJob(record.jobId)">提交</a>
            </template>
            <a-table
              slot="expandedRowRender"
              slot-scope="record"
              :rowKey="(r, i) => i"
              :columns="innerColumns"
              :showHeader="false"
              :data-source="record.childrenList"
              :pagination="false"
            >
              <span slot="innerAction" slot-scope="record" class="table-operation">
                <a @click="toTeamAddTask(record,2)">编辑</a>
                <a-divider type="vertical" />
                <a @click="inputs_get_deleteLeaderListItem(record.jobId)">删除</a>
                <a-divider type="vertical" v-show="record.jobTypeName=='录入'"/>
                <router-link v-show="record.jobTypeName=='录入'" :to="{path:'/inputs_data',query:{cubeId:record.cubeId,cubeName:encodeURI(record.cubeName),jobId:record.jobId,editable:1}}" target="_blank">去工作</router-link>
                <a-divider type="vertical" v-show="record.jobTypeName=='负责'"/>
                <router-link v-show="record.jobTypeName=='录入'" :to="{path:'/inputs_data',query:{cubeId:record.cubeId,cubeName:encodeURI(record.cube),jobId:record.jobId,editable:0}}" target="_blank">去查看</router-link>
                <a-divider type="vertical" v-show="record.jobTypeName=='审查'"/>
                <router-link v-show="record.jobTypeName=='录入'" :to="{path:'/inputs_data',query:{cubeId:record.cubeId,cubeName:encodeURI(record.cubeName),jobId:record.jobId,editable:2}}" target="_blank">去审核</router-link>
              </span>
            <template slot="jobName" slot-scope="text,record">
                  <span @click="popProgress(record)" class="hoverColor">{{text}}</span>
            </template>
            <template slot="jobProgress" slot-scope="text">
              <div style="position:relative;">
                    <a-progress
                      :percent="Number(text)"
                      :strokeWidth="20"
                      :showInfo="false"
                      strokeColor="rgb(70,217,147)"
                    >
                    </a-progress>
                    <span style="color: #222;position:absolute;line-height:23px;left: 50%;transform: translateX(-50%);">{{text}}%</span>
              </div>
            </template>
            <template slot="iconInfo" slot-scope="text">
                <div>
                    <span :title="text">{{text|getTxtShow(10)}}</span>
                </div>
            </template>
            </a-table>
            <!-- 子表end -->
        </a-table>
        <a-table :pagination="pagination2" :rowKey="(r, i) => i" :columns="allColumns" bordered @change="PageChange2" :data-source="allData" v-show="activeKey==2"  style="padding:10px 20px 0">
            <template slot="iconInfo" slot-scope="text,record">
                <div  style="display: flex;justify-content: space-between;align-items: baseline;">
                    <span @click="popProgress(record)" class="hoverColor">{{text}}</span>
                    <a-button type="link" class="fr"  @click="popup.stop(record)"><a-icon style="fontSize:18px" type="info-circle"/></a-button>
                </div>
            </template>
            <template slot="jobProgress" slot-scope="text">
              <div style="position:relative;">
                    <a-progress
                      :percent="Number(text)"
                      :strokeWidth="20"
                      :showInfo="false"
                      strokeColor="rgb(70,217,147)"
                    >
                    </a-progress>
                    <span style="color: #222;position:absolute;line-height:23px;left: 50%;transform: translateX(-50%);">{{text}}%</span>
              </div>
            </template>
        </a-table>
        <a-modal v-model="Visible" :footer="null" title="分配详情" centered>
          <div class="select">
            <!-- 选择小组 -->
            <div class="select-item">
                <a-row type="flex" :gutter="[16,32]">
                <a-col flex="120px">选择小组</a-col>
                <a-col flex="300px">
                    <a-select
                      style="width: 100%"
                      placeholder="选择小组"
                      v-model="VisibleList.teamName"
                      disabled
                    >
                    </a-select>
                </a-col>
            </a-row>
            </div>
            <!-- 任务名称 -->
            <div class="select-item">
                <a-row type="flex" :gutter="[16,32]">
                <a-col flex="120px">任务名称</a-col>
                <a-col flex="300px">
                        <a-select
                        style="width: 100%"
                        placeholder="选择数据库"
                          v-model="VisibleList.jobName"
                          disabled
                        >
                        </a-select>
                </a-col>
            </a-row>
            </div>
            <!-- 分配工作 -->
            <div class="select-item">
                <a-row type="flex" :gutter="[16,32]">
                <a-col flex="120px">分配工作</a-col>
                <a-col flex="200px">
                    <a-select
                    style="width: 100%"
                      v-model="VisibleList.cubeName"
                      disabled
                    >
                    </a-select>
                </a-col>
                <a-col flex="220px">
                    <div style="width:220px">
                        <a-select
                        style="width: 100%"
                          :value="'已选地区：'+VisibleList.regionNames"
                          disabled
                        >
                        </a-select>
                    </div>
                </a-col>
                <a-col flex="220px">
                    <div style="width:220px">
                      <a-select
                        style="width: 100%"
                          :value="'已选时间：'+VisibleList.timeNames"
                          disabled
                        >
                        </a-select>
                    </div>
                </a-col>
            </a-row>
            </div>
            <!-- 时间区间 -->
            <div class="select-item">
                <a-row type="flex" :gutter="[16,32]">
                <a-col flex="120px">时间区间</a-col>
                <a-col flex="200px">
                    <a-date-picker disabled v-model="VisibleList.startDate" valueFormat="YYYY-MM-DD"/>
                </a-col>
                <a-col flex="200px">
                    <a-date-picker disabled v-model="VisibleList.endDate" valueFormat="YYYY-MM-DD"/>
                </a-col>
            </a-row>
            </div>
            <div class="btns">
              <a-button type="button" @click="Visible=!Visible" size="large">确定</a-button>
            </div>
        </div>
        </a-modal>
        <Progress ref="proChild"></Progress>
    </div>
</template>

<script>
import { messageTips } from '@/until/tools';
import Progress from './components/modalProgress.vue'
import Allocation from './Allocation.vue';
import { getCookie } from '../../until/tools';
const columns = [
{
    title: '序号',
    // dataIndex: 'sort',
    key: '1',
    customRender: (text, record, index) => `${index + 1}`,
    align:'center',
    width: '100px',
  },
  {
    title: '任务名',
    dataIndex: 'jobName',
    scopedSlots: { customRender: 'jobName'},
    key: '2',
    width: '200px',
  },
  {
    title: '数据库',
    dataIndex: 'cubeName',
    key: '3',
    scopedSlots: { customRender: 'iconInfo'},
    width: '240px',
  },
  {
    title: '审核人',
    dataIndex: 'checkUserName',
    key: 'x',
    width: '100px',
  },
  {
    title: '开始时间',
    dataIndex: 'startDate',
    key: '4',
    width: '200px',
  },
  {
    title: '结束时间',
    dataIndex: 'endDate',
    key: '5',
    width: '200px',
  },  
  
  {
    title: '当前状态',
    dataIndex: 'jobStatusName',
    key: '6',
    width: '100px',
  },
  {
    title: '完成度',
    dataIndex: 'jobProgress',
    key: '7',
    scopedSlots: { customRender: 'jobProgress'},
    width: '150px',
  },
  {
    title: '工作说明',
    dataIndex: 'memo',
    key: '8',
  },
    {
    title: '操作',
    key: 'operation',
    align:'center',
    scopedSlots: {
      customRender: 'action',
    },
    width: '200px',
  },
];
const innerColumns = [
{
    title: '序号',
    // dataIndex: 'sort',
    key: '1',
    customRender: (text, record) =>record.inputUserName,
    align:'center',
    width: '99px',
  },
  {
    title: '任务名',
    dataIndex: 'jobName',
    scopedSlots: { customRender: 'jobName'},
    key: '2',
    width: '200px',
  },
  {
    title: '数据库',
    dataIndex: 'cubeName',
    key: '3',
    scopedSlots: { customRender: 'iconInfo'},
    width: '240px',
  },
  {
    title: '审核人',
    dataIndex: 'checkUserName',
    key: 'x',
    width: '100px',
  },
  {
    title: '开始时间',
    dataIndex: 'startDate',
    key: '4',
    width: '200px',
  },
  {
    title: '结束时间',
    dataIndex: 'endDate',
    key: '5',
    width: '200px',
  },
  {
    title: '当前状态',
    dataIndex: 'jobStatusName',
    key: '6',
    width: '100px',
  },
  {
    title: '完成度',
    dataIndex: 'jobProgress',
    key: '7',
    scopedSlots: { customRender: 'jobProgress'},
    width: '150px',
  },
  {
    title: '工作说明',
    dataIndex: 'memo',
    key: '8',
  },
    {
    title: '操作',
    key: 'operation',
    align:'center',
    scopedSlots: {
      customRender: 'innerAction',
    },
    width: '200px',
  },
];

const allColumns = [
{
    title: '序号',
    customRender: (text, record, index) => `${index + 1}`,
    key: '1',
    align:'center',
  },
  {
    title: '任务名称',
    dataIndex: 'jobName',
    key: '2',
    scopedSlots: { customRender: 'iconInfo'}
  },
  {
    title: '分配时间',
    dataIndex: 'createTime',
    key: '3',
  },
  {
    title: '开始时间',
    dataIndex: 'startDate',
    key: '4',
  },
  {
    title: '结束时间',
    dataIndex: 'endDate',
    key: '5',
  },
  {
    title: '负责小组',
    dataIndex: 'teamName',
    key: '6',
  },
  {
    title: '当前状态',
    dataIndex: 'jobStatusName',
    key: '7',
  },
  {
    title: '完成度',
    dataIndex: 'jobProgress',
    key: '8',
    scopedSlots: { customRender: 'jobProgress'}
  },
];
export default {
    components:{
      Progress,
        Allocation
    },
    filters: {
      getTxtShow(value, num) {
        if (!value) return "";
        if (value.length > num) {
          return value.slice(0, num) + "...";
        }
        return value;
      },
  },
    data() {
        return {
            switchChecked:false,
            expandedRowKeys:[],
            jobStatus:undefined,
            Visible:false,
            VisibleList:[],
            allocationList:[],
            columns,
            innerColumns,
            allColumns,
            allData:[],
            activeKey:"1",
            keyword:"",
            pagination: {
              total: 0,
              current:1,
              pageSize: 10,//每页中显示10条数据
              // showSizeChanger: true,
              pageSizeOptions: ["10", "20", "50", "100"],//每页中显示的数据
              showTotal: total => `共有 ${total} 条数据`,  //分页中显示总的数据
            },
            pagination2: {
              total: 0,
              current:1,
              pageSize: 10,//每页中显示10条数据
              // showSizeChanger: true,
              pageSizeOptions: ["10", "20", "50", "100"],//每页中显示的数据
              showTotal: total => `共有 ${total} 条数据`,  //分页中显示总的数据
              showQuickJumper:true,
              showSizeChanger:true
            },
            roleName:getCookie('roleName')
        }
    },
    computed: {
      userInfo() {
        return this.$store.getters.getUserInfo;
      },
    },
    methods:{
      changeStatus() {
        this.inputs_get_leaderList(1);
        this.pagination.current = 1;
      },
      changeSwitch(value) {
        if(value) {
          this.allocationList.forEach(item=>{
            let index = this.expandedRowKeys.indexOf(item.jobId);
            if(index===-1&&item.childrenList.length!==0) {
              this.expandedRowKeys.push(item.jobId);
            }
          })
        } else {
          this.expandedRowKeys = [];
        }
      },
      expandFP(value,e) {
        let index = this.expandedRowKeys.indexOf(e.jobId);
        if(value) {          
          if(index===-1) {
            this.expandedRowKeys.push(e.jobId);
          }
        } else {
          this.expandedRowKeys.splice(index,1);
        }
      },
      toInputs_data(record,val){
        if(val==0){
            let {href} = this.$router.resolve({path: '/inputs_data',
              query:{cubeId:record.cubeId,
                cubeName:encodeURI(record.cube),
                jobId:record.jobId,
                editable:val
              }
            });
          window.open(href, '_blank');
        }else{
          this.$router.push({
            path:"/inputs_data",
            query:{
              cubeId:record.cubeId,
              cubeName:encodeURI(record.cubeName),
              jobId:record.jobId,
              editable:val
            }
          })
        }
      },
        //弹窗
        popup(record){
          this.Visible = true;
          this.VisibleList=record
        },
        popProgress(record){
          this.$refs.proChild.popProgress()
          this.$refs.proChild.progressList=record.logList
        },
        onSearch(value) {
          this.keyword = value
          this.inputs_get_leaderList(1)
        },
        //删除人员
        inputs_get_deleteLeaderListItem(e) {
            let params ={
              jobId:e
            }
            var that = this;
            this.$confirm({
                title: '提示',
                content: '您确定要删除吗？',
                okText: '确定',
                cancelText: '取消',
                centered:true,
                onOk() {
                    that.$http.inputs_get_deleteLeaderListItem(params).then(res=>{
                            if(res.success) {
                                messageTips(res.message,3);
                                that.inputs_get_leaderList()
                            } else {
                                messageTips(res.message,1);
                            }
                    })
                }
            });
        },
        //提交任务
        inputs_get_submitJob(jobId){
          let params={
            jobId: jobId
          }
          this.$http.inputs_get_submitJob(params).then(res=>{
            if(res.success){
              messageTips(res.message,3)
              this.inputs_get_leaderList(this.pagination.current)
            }
          })
        },
        //获取列表
        inputs_get_leaderList(current){
          let params ={
            currentPage:current,
            jobName:this.keyword
          }
          if(this.jobStatus!==undefined) {
            params.jobStatus = this.jobStatus;
          }
          this.$http.inputs_get_leaderList(params).then(res=>{
            if(res.success){
              this.pagination.total=res.page.allSize
              const data =res.list
              if(data!==undefined){
              const temp = data.map((item)=>{
                  return item
              })
              this.allocationList=temp
            }else{
              this.allocationList=[]
            }
            }
          })
        },
        //获取所有任务
        inputs_get_allTaskList(page=1,size=10){
          this.$http.inputs_get_allTaskList({currentPage:page,pageSize:size}).then(res=>{
            if(res.success){
              this.allData=res.list;
              this.pagination2.total = res.page.allSize;
              this.pagination2.pageSize = res.page.pageSize;
            }
          })
        },
        //跳转
        toTeamAddTask(record,editOrNewTask){
          //1新分配 2编辑
          if(editOrNewTask==1){
            this.$router.push({
              path: '/inputs_teamAddTask',
              query:{
                parentJobId:record.jobId,
                cubeId:record.cubeId,
                jobName:record.jobName,
                jobId:record.jobId,
              }
            })
          }else{
            let params ={
              jobId: record.jobId
            }
            this.$http.inputs_get_detail(params).then(res=>{
              if(res.job){
                let obj = JSON.stringify(res.job);
                 this.$router.push({
                  path: '/inputs_teamAddTask',
                  query:{
                    detailList:obj,
                  }
                 });
              }
            })
          }
        },
        //翻页
        PageChange(pageCurrent){
          this.pagination.current=pageCurrent.current
          this.inputs_get_leaderList(pageCurrent.current)
        },
        PageChange2(pageCurrent){
          this.pagination2.current=pageCurrent.current;
          let size = pageCurrent.pageSize;
          this.inputs_get_allTaskList(pageCurrent.current,size)
        }
    },
    mounted() {
      this.inputs_get_leaderList()
      this.inputs_get_allTaskList()
    }
}
</script>

<style lang="scss" scoped>
@import '../../scss/teamAllocation.scss';
 /deep/.noExpand .ant-table-row-expand-icon {
 display: none !important;
}
</style>
